<template>
  <div :class="($route.name == 'tests' && $vuetify.breakpoint.mdAndUp) && 'px-10'">
    <div class="titleProfileSection mt-5 ">
      <v-icon small color="#4597DF">mdi-circle</v-icon>
      تست‌های روانشناسی
    </div>
    <div>
      <v-row>
        <v-col cols="12" lg="3" class="fill-height" v-for="(test , i ) in tests" :key="i">
          <div class="testBox " @click="goToHistoryPage(test)">
            <div class=" d-flex">
              <div class="">
                <img :src="test.img" alt="">
              </div>
              <div class="fill-height mr-1 d-flex justify-center flex-column testTitle pt-3">
                {{ test.title }}
              </div>
            </div>
            <div class="testDesc">
              {{ test.desc }}
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
    <v-row class="mt-5">
      <v-col cols="12" lg="6" v-if="platFormInfoLS?.id != 4">
        <pre-session-cart img="../../../assets/image/preSession-1.jpg"/>
      </v-col>
      <v-col cols="12" lg="6">
        <router-link :to="{name:'roots-in-soil'}">
          <img src="../../../assets/webText.jpg" width="100%" class="campaignImage" alt="">
        </router-link>
      </v-col>
    </v-row>
  </div>
</template>

<script>
/*eslint-disable*/
import PreSessionCart from "@/components/PreSession/preSessionCart.vue";

export default {
  components: {PreSessionCart},
  data() {
    return {
      tests: [
        {
          id: "7",
          title: "تست عشق استرنبرگ (STLS)",
          url: "https://simiaroom.com/tests/sternberg-triangular-love-scal/",
          img: require('../../../assets/tests/icons/strinburg.png'),
          desc: 'از نظر استرنبرگ عشق کامل مانند مثلث متساوی الاضلاع است و سه ضلع صمیمیت، اشتیاق و تعهد دارد و اگر هر یک از این سه نباشند، یا ما آسیب می‌بینیم. '
        },
        {
          id: "4",
          title: "تست اهمال کاری (TPS)",
          url: "https://simiaroom.com/tests/procrastination-scale/",
          img: require('../../../assets/tests/icons/ehmal.png'),
          desc: "یکی از روش‌های تشخیص در روانشناسی با استفاده از تست‌های روانی است. تست اهمال کاری تاکمن در سال 1991 توسط فردی به همین نام و متشکل از 16 سوال طراحی شد تا میزان تمایل فرد در به تعویق انداختن امور را بررسی کند.\n"
        },
        {
          id: "1",
          title: "تست افسردگی بک(BDI-ll) ",
          url: "https://simiaroom.com/tests/beck",
          img: require('../../../assets/tests/icons/depression.png'),
          desc: "به یاد داشته باشید تست‌های روانشناسی تنها به‌عنوان ابزار کمکی هستند نه راه تشخیص قطعی و درمان افسردگی.پرسشنامه افسردگی بک، یکی از ابزارهایی است.\n",
        },
        {
          id: "11",
          title: " تست کیفیت زندگی برای مهاجران (QOL)",
          url: "https://app.simiaroom.com/fa/test/11",
          img: require('../../../assets/tests/icons/migration.png'),
          desc: "سوالات پیش رو مجموعه‌ای از سوالات پرسشنامه کیفیت زندگی سازمان جهانی بهداشت و پرسشنامه رضایت از مهاجرت است که به منظور بررسی عمومی کیفیت و رضایت از زندگی، برای ایرانیان خارج از کشور جمع آوری شده است. \n"
        },
        {
          id: "3",
          title: "چک لیست نشان های روانی (SCL-90)",
          url: "https://simiaroom.com/tests/symptom-checklist-90/",
          img: require('../../../assets/tests/icons/personality-disorder.png'),
          desc: "این یک چک آپ سلامت روان است. با شرکت در چک لیست نشان های روانی SCL-90 یک دیدگاه کلی نسبت به وضعیت سلامت روان خود کسب می‌کنیم و متوجه می‌شویم که روان ما در چه زمینه‌ای آزرده شده است؟\n",
        },
        {
          id: "8",
          title: "تست اختلال عملکرد جنسی",
          url: "https://simiaroom.com/tests/sexual-dysfunction-questionnaire/",
          img: require('../../../assets/tests/icons/sexual-dysfunction.jpg'),
          desc: "رضایت جنسی فاکتور مهمی در رسیدن به رضایت از زندگی زناشویی و دوام ازدواج است. تست اختلال عملکرد جنسی کمک می‌کند که در مورد میل جنسی خودمان آگاهی بیشتری پیدا کنیم."
        },
        {
          id: "17",
          title: "تست شخصیت شناسی نئو",
          url: "https://simiaroom.com/tests/neo-pi-r-personality/",
          img: require('../../../assets/tests/icons/personality.jpg'),
          desc: "آیا ما شخصیت خودمان را می‌شناسیم؟ چقدر با ویژگی‌های روانی خود آشنایی داریم؟  با استفاده از تست نئو به طیف گسترده‌ای از اطلاعات شخصیتی می‌رسیم که ما را در انتخاب درست مسیرهای زندگی کمک می‌کند. \n",
        },
        {
          id: "12",
          title: "تست اضطراب همیلتون (HARS)",
          url: "https://simiaroom.com/simiaroomcom/tests/anxiety/",
          img: require('../../../assets/tests/icons/2-11.jpg'),
          desc: "انجام دادن تست اضطراب همیلتون کمک می‌کند که از میزان و شدت اضطراب در زندگی خود، باخبر شویم. اضطراب ما یک حالت روانی معمولی، نوعی انگیزه، پاسخ به موقعیتی خاص، یک ویژگی شخصیتی  است که باید درمان شود؟ \n"

        },
        {
          id: "22",
          title: "تست وسواس",
          url: "https://simiaroom.com/tests/obsession/",
          img: require('../../../assets/tests/icons/-1-q93yh4zee086bxn4kj3zrgsmpfiiikou39fnlyt8po.png'),
          desc: "تشخیص به موقع اختلالات وسواس اجباری به درمان کمک می‌کند. تست وسواس مادزلی یکی از روش‌های سریع و راحت تشخیص است که شامل 30 عبارت با پاسخ بلی/خیر است."
        },
        {
          id: "20",
          title: "تست آمادگی برای مهاجرت",
          url: "https://app.simiaroom.com/fa/test/20",
          img: require('../../../assets/tests/icons/immigration.png'),
          desc: "چک لیست آمادگی روانی برای مهاجرت، کمک می‌کند شرایط روانی پیش از مهاجرت را بسنجید. توجه کنید که این چک لیست، یک پاسخ صحیح مطلق ندارد و این بدان معنی نیست که اگر پاسخ دیگری دارید، پس آماده مهاجرت نیستید.\n"
        },
        {
          id: "21",
          title: "تست شرمساری از بدن",
          url: "https://app.simiaroom.com/fa/test/21",
          img: require('../../../assets/tests/icons/body-shaming.png'),
          desc: "این مجموعه سوالات به احساس رضایت از ظاهر شما می‌پردازد. توجه داشته باشید که نتایج، به صورت مطلق نشان‌دهنده وجود یا عدم شرمساری از بدن نیست و بهتر است این موضوع با نظارت و همراهی یک متخصص، بررسی شود.\n"
        },
        {
          id: "26",
          title: "تست ADHD",
          url: "https://app.simiaroom.com/fa/test/26",
          img: require('../../../assets/tests/icons/adhd-150x150.png'),
          desc: "بسیاری از افراد از دریافت تشخیص رسمی پرهیز می‌کنند. از خود می‌پرسند، خب من که قصد مصرف دارو ندارم، پس چه فایده‌ای دارد؟ یا نگران آن هستند که دریافت برچسب ADHD، بیش از آنکه مفید باشد، به آنها آسیب برساند. اما تشخیص درست و به موقع مزایای زیادی برای شما به همراه دارد.\n"
        },
      ]
    }
  },
  methods: {
    goToHistoryPage(item) {
      this.$store.dispatch('setTest', item)
      this.$router.push({name: "test_history", params: {testID: item.id, lang: 'fa'}})
    }
  }
}
</script>

<style scoped>
.testBox {
  background: white;
  border: solid 1px #d1dcff;
  margin: 0px auto;
  padding: 8px;
  border-radius: 10px;
  text-align: center;
  min-height: 170px;
  cursor: pointer;
}

.testBox img {
  width: 40px;
}

.testTitle {
  font-size: 15px;
  font-weight: bolder;
}

.testDesc {
  font-size: 12px;
  text-align: right;
  line-height: 20pt;
}

.campaignImage {
  border-radius: 10px;
}
</style>
