<template>
  <div>
    <v-stepper v-model="window" elevation="0" class="pa-0 ma-0 ">
      <v-stepper-content step="1" class="pa-0 ma-0 ">
        <img v-if="$vuetify.breakpoint.mdAndUp" src="../../assets/image/preSession-1.jpg" @click="changeWindow"
             width="100%"
             class="campaignImage"
             alt="">
        <img v-else src="../../assets/lead/phone.jpg" @click="changeWindow" width="100%" class="campaignImage"
             alt="">


        {{ imageSource }}


      </v-stepper-content>
      <v-stepper-content step="2" class="">
        <div class="mt-10">
          <div class="titleBox text-center">
            برای رزرو وقت پیش‌مشاوره لطفا شماره تماس خود را وارد کنید.
          </div>
          <div class="descBox text-center" v-if="$vuetify.breakpoint.mdAndUp">
            از طریق این شماره در واتساپ یا تلگرام هماهنگی‌های لازم صورت میگیرد
          </div>
          <div class="formBox">
            <div class="ltrInput mt-5">
              <v-form ref="phoneForm">
                <v-text-field outlined style="border-radius: 15px" v-model="phone" :rules="phoneRules"
                              label="شماره تماس واتساپ / تلگرام">
                  <template v-slot:append>
                    <v-btn color="primary" @click="submitPhone" elevation="0" class=""
                           style="margin-top: -7px;border-radius: 10px">
                      <v-icon small style="transform: rotate(180deg)">mdi-send</v-icon>
                    </v-btn>
                  </template>
                </v-text-field>
              </v-form>
            </div>
          </div>
        </div>
      </v-stepper-content>
      <v-stepper-content step="3">
        <div class="titleBox text-center">
          برای رزرو وقت پیش‌مشاوره لطفا کشور خود را انتخاب کنید.
        </div>
        <div class="descBox text-center" v-if="$vuetify.breakpoint.mdAndUp">
          زمان جلسات براساس منطقه زمانی شما برنامه ریزی می‌شوند.
        </div>
        <div class="formBox">
          <v-form ref="country">
            <v-autocomplete style="border-radius: 15px" v-if="window == 3" id="UserCountry" placeholder="تایپ کنید..."
                            :items="contires"
                            item-text="label"
                            item-value="id"
                            v-model="country_id"
                            :rules="requiredRules" outlined class="profileTextFiled mt-5"
                            :label="$t('country')">
              <template v-slot:append>
                <v-btn color="primary" @click="submitCountry" elevation="0" class=""
                       style="margin-top: -7px;border-radius: 10px">
                  <v-icon small style="transform: rotate(180deg)">mdi-send</v-icon>
                </v-btn>
              </template>
            </v-autocomplete>
          </v-form>
        </div>

      </v-stepper-content>
    </v-stepper>
  </div>
</template>

<script>
import AuthMixins from "@/mixins/AuthMixins";
import {mapGetters} from "vuex";

export default {
  mixins: [AuthMixins],
  props: {
    imageSource: {
      type: String
    }
  },
  computed: {
    ...mapGetters({
      'profile': 'getProfile',
    }),
    user() {
      return JSON.parse(JSON.stringify(this.profile));
    },
    sessions() {
      return this.profile.four_future_sessions
    }
  },
  data() {
    return {
      window: 1,
      phone: null,
      country_id: null,
    }
  },
  mounted() {
    this.phone = this.user.cel
  },

  methods: {
    changeWindow() {
      // this.window = 2
      // return;
      if (this.phone) {
        if (this.user.country_name) {
          this.redirectToPreSession(this.phone.replaceAll(' ', ''))
        } else {
          this.window = 3;
        }
      } else {
        this.window = 2;
      }
    },
    redirectToPreSession() {
      this.$emitEvent('setLoading', true)
      this.requesting('session', 'getPreSessionPackage', {}, {refer: 'panel'}).then((resp) => {
        if (resp.data.continue) {
          this.$router.push({name: 'onboarding_new', params: {lang: 'fa'}})
        } else {
          this.$emitEvent('notify', {title: 'درخواست شما برای جلسه پیش‌مشاوره ثبت‌ شد.', color: 'green'})
          this.window = 1
        }
      }).finally(() => {
        this.$emitEvent('setLoading', false)
      })
      // const parsedNumber = parsePhoneNumberFromString(phone);
      // if (parsedNumber.country == 'IR') {
      // } else {
      //
      // }
    },

    submitPhone() {
      console.log('hrere')
      if (this.$refs.phoneForm.validate()) {
        let data = {
          mobile: this.phone.replaceAll(' ', ''),
        }
        this.$emitEvent('setLoading', true)

        this.requesting('auth', 'editProfile', '', data).then(() => {
          if (this.user.country_name) {
            this.redirectToPreSession()
          } else {
            this.window = 3
          }
        }).finally(() => {
          this.$emitEvent('setLoading', false)
        })

      }
    },

    submitCountry() {

      if (this.$refs.country.validate()) {

        let country = this.contires.find(item => item.id == this.country_id)

        let data = {
          "country_id": this.country_id,
          "country_name": country.label
        }

        this.$emitEvent('setLoading', true)

        this.requesting('auth', 'editProfile', '', data).then(() => {
          this.redirectToPreSession()
        }).finally(() => {
          this.$emitEvent('setLoading', false)
        })

      }
    }
  },


}
</script>

<style scoped>
@media (min-width: 200px) and (max-width: 600px) {
  .titleBox {
    line-height: 28pt;
    min-height: 100px !important;

  }

  .formBox {
    width: 100% !important;
  }
}

.descBox {
  line-height: 25pt;
  color: #67748e !important;
  font-size: 15px;
}

.formBox {
  width: 50%;
  margin: 0px auto;
}

.campaignImage {
  border-radius: 10px;
  cursor: pointer;
}

.titleBox {
  font-size: 18px;
  font-weight: bolder;
  margin-top: 8px;
  color: #344767;
}


</style>
